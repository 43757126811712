<template>
    <div>
        <psi-content-loader v-if="dataLoading"></psi-content-loader>
        <div v-if="Object.keys(testApplicants).length > 0 && !dataLoading">
            <v-tabs v-model="tab">
                <template v-for="(key, index) in Object.keys(testApplicants)">
                    <v-tab :key="index">{{ key }}</v-tab>
                </template>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <template v-for="(key, index) in Object.keys(testApplicants)">
                    <v-tab-item :key="`tab-item-${index}`">
                        <v-data-table
                            single-select
                            item-key="id"
                            v-model="selected"
                            disable-pagination
                            hide-default-footer
                            show-select
                            :headers="headers"
                            :items="testApplicants[key]"
                            @item-selected="itemSelect"
                        >
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.address="{ item }">
                                <span>{{ item.address.address }}</span
                                ><br />
                                <span
                                    >{{ item.address.city }},
                                    {{ item.address.state }}
                                    {{ item.address.zip }}</span
                                >
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.accounts="{ item }">
                                <div v-if="$_.has(item, 'meta.info.Accounts')">
                                    <template
                                        v-for="(key, index) in [
                                            'Total',
                                            'Bankcard',
                                            'Revolving',
                                            'Installment',
                                            'Mortgage',
                                        ]"
                                    >
                                        <span
                                            class="text-caption"
                                            :key="`label-${index}`"
                                            >{{ key }}:</span
                                        >
                                        <span
                                            :key="index"
                                            class="
                                                font-weight-bold
                                                text-right text-caption
                                            "
                                        >
                                            {{ item.meta.info.Accounts[key] }}
                                        </span>
                                        <span
                                            v-if="index < 4"
                                            vertical
                                            :key="`divider-${index}`"
                                        >
                                            |
                                        </span>
                                    </template>
                                </div>
                                <div v-else>
                                    <p>{{ $_.get(item, "meta.info") }}</p>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.records="{ item }">
                                <div v-if="$_.has(item, 'meta.info.Records')">
                                    <template
                                        v-for="(key, index) in [
                                            'Bankruptcy',
                                            'Public',
                                            'Debt',
                                        ]"
                                    >
                                        <span
                                            class="text-caption"
                                            :key="`label-${index}`"
                                            >{{ key }}:</span
                                        >
                                        <v-chip
                                            small
                                            color="error"
                                            dark
                                            v-if="
                                                key === 'Debt' &&
                                                $_.get(
                                                    item,
                                                    `meta.info.Records.${key}`
                                                )
                                            "
                                            :key="`chip-${index}`"
                                            >{{
                                                item.meta.info.Records[key]
                                            }}</v-chip
                                        >
                                        <span
                                            v-else
                                            :key="index"
                                            class="
                                                font-weight-bold
                                                text-right text-caption
                                            "
                                        >
                                            {{ item.meta.info.Records[key] }}
                                        </span>
                                        <span
                                            v-if="index < 1"
                                            vertical
                                            :key="`divider-${index}`"
                                        >
                                            |
                                        </span>
                                    </template>
                                </div>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </template>
            </v-tabs-items>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "applicant-test-selector",
    components: {},
    props: {},
    data() {
        return {
            tab: null,
            selected: [],
            headers: [
                {
                    text: "Name",
                    align: "start",
                    sortable: true,
                    value: "profile.name",
                },
                {
                    text: "Score",
                    align: "start",
                    sortable: true,
                    value: "meta.info.FICO.Score",
                },
                {
                    text: "SSN",
                    align: "start",
                    sortable: true,
                    value: "profile.ssn",
                },
                {
                    text: "Address",
                    align: "start",
                    sortable: true,
                    value: "address",
                },
                {
                    text: "Merged Crime",
                    align: "start",
                    sortable: true,
                    value: "merge_crime_id",
                },
                {
                    text: "Accounts/Info",
                    align: "start",
                    sortable: false,
                    value: "accounts",
                },
                {
                    text: "Records",
                    align: "start",
                    sortable: false,
                    value: "records",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Applications", ["testApplicants", "dataLoading"]),
    },
    watch: {},
    created() {},
    mounted() {
        this.getTestApplicants();
    },
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Applications", ["getTestApplicants"]),
        itemSelect({ item }) {
            this.$emit("select:applicant", item);
        },
    },
};
</script>

<style scoped>
</style>